<template lang="pug">
	.textarea
		textarea(:placeholder="contents.placeholder")
		span {{ contents.label }} é obrigatório
</template>

<script>
import { props } from '@/mixins/component'

export default {
	name: "component-textarea",
}
</script>

<style lang="stylus" scoped src="./Textarea.styl"></style>
